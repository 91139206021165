
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  onBeforeMount,
  ref,
} from "vue";
import { useStore } from "@/store";
import router from "@/router";
import { generateRandomNumber, getDeviceOrientation } from "@/utils";
import { AppActions, AppMutation } from "@/store/types";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import BussEventType from "@/types/BussEventType";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "login",
  setup() {
    const store = useStore();
    const emitter = inject("emitter") as Emitter;
    const { t } = useI18n();

    const errorMessage = ref<string>("");
    const captcha = ref<string>("");
    const username = ref<string>("");
    const password = ref<string>("");
    const capchaInput = ref<string>(`${generateRandomNumber(1000, 9999)}`);

    //#region Methods

    const visitCustumerService = () => {
      window.open(process.env.CUSTOMER_SERVICE_URL, "_blank");
    };

    const loginTo = () => {
      if (username.value === "") {
        errorMessage.value = t("login.pleaseenterusername");
        return;
      } else if (password.value === "") {
        errorMessage.value = t("login.pleaseenterpwd");
        return;
      } else if (capchaInput.value === "") {
        errorMessage.value = t("login.invalidcode");
        return;
      }

      if (captcha.value.toString() !== capchaInput.value) {
        errorMessage.value = t("login.invalidcode");

        return;
      }

      errorMessage.value = "";

      store.commit(AppMutation.SHOW_LOADING);
      store
        .dispatch(AppActions.USER_LOGIN, {
          username: username.value,
          password: password.value,
        })
        .then(() => {
          store.commit(AppMutation.HIDE_LOADING);
          router.push("/");
        })
        .catch((error) => {
          store.commit(AppMutation.HIDE_LOADING);
          errorMessage.value = error;
        });
    };

    const onCapchaKeydown = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === "enter") {
        // request()
        event.preventDefault();
        return;
      }

      if (event.key.toLowerCase() === "e") {
        event.preventDefault();
        return;
      }

      if (document.getSelection()?.toString() === captcha.value.toString()) {
        return;
      }

      if (
        captcha.value.toString().length > 3 &&
        event.key.toLowerCase() !== "backspace"
      ) {
        event.preventDefault();
        return;
      }
    };

    const onShowLanguageMsbBox = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "lang",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };
    //#endregion

    //#region Vue Hooks
    onBeforeMount(() => {
      if (serverLine.value <= 0 && serverLine.value !== -1) {
        router.push({ name: "select-server-line" });

        return;
      }
    });
    //#endregion

    //#region Computed
    const serverLine = computed((): number => store.getters["serverLine"]);
    const isMobileDisplay = computed((): boolean => store.getters["isMobile"]);
    const isMobileLandscape = computed(() => {
      if (isMobileDisplay.value) {
        return getDeviceOrientation() === "landscape";
      }
      return false;
    });

    const lang = computed((): string => store.getters["lang"]);
    //#endregion

    return {
      username,
      password,
      capchaInput,
      captcha,
      serverLine,
      errorMessage,
      isMobileDisplay,
      isMobileLandscape,
      lang,
      t,
      onCapchaKeydown,
      loginTo,
      visitCustumerService,
      onShowLanguageMsbBox,
    };
  },
});
